<template>
  <el-card
    shadow="never"
    class="aui-card--fill"
  >
    <div class="mod-sys__params">
      <el-form
        :inline="true"
        :model="dataForm"
      >
        <div class="flex-between">
          <div class="flex-start">
            <el-form-item>
              <el-input
                v-model="dataForm.searchStr"
                placeholder="搜索关键字"
                clearable
                @clear="handleFilter"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter()"
              >
                搜索
              </el-button>
            </el-form-item>
          </div>
          <div class="flex-end">
            <el-form-item>
              <el-button
                size="small"
                @click="$router.push({path: '/questionTemplate'})"
              >
                返 回
              </el-button>
              <el-button
                size="small"
                type="primary"
                @click="$router.push({path: '/linkRiskSave',query: {c: c}})"
              >
                新增
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="danger"
                @click="deleteHandle(null,{questionnaireCode: c})"
              >
                删除
              </el-button>
            </el-form-item>
          </div>
        </div>

      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%;"
        @selection-change="dataListSelectionChangeHandle"
      >
        <el-table-column
          type="selection"
          width="50"
        />
        <el-table-column
          prop="riskName"
          label="风险名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="riskLevel"
          label="风险等级"
        >
          <template v-slot="scope">
            {{ riskLevelObj[scope.row.riskLevel] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="riskType"
          label="风险类别"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="riskFactor"
          label="风险系数"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            {{ scope.row.riskFactor ? scope.row.riskFactor : '' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="questionCodes"
          :show-overflow-tooltip="true"
          label="涉及题目编号"
          header-align="center"
          align="center"
        />
        <el-table-column
          prop="createName"
          label="创建者"
          header-align="center"
          align="center"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          header-align="center"
          align="center"
        />
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="120"
        >
          <template v-slot="scope">
            <el-link
              :underline="false"
              class="mr10"
              type="primary"
              @click="$router.push({path:'/linkRiskSave', query:{ id:scope.row.id, c: c}})"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              :underline="false"
              type="primary"
              class="mr10"
              @click="deleteHandle(scope.row.id,{questionnaireCode: c})"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="page"
        :limit.sync="pageSize"
        @pagination="query"
      />

    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import pagination from '@core/components/pagination/Pagination.vue'

export default {
  components: { pagination },
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/questionnaire/risk/match/page',
        getDataListIsPage: true,
        deleteURL: '/risk/match/delete',
        deleteIsBatch: true,
      },
      riskLevelObj: {
        1: '极低',
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
      dataForm: {
        questionnaireCode: this.$route.query.c,
      },
      c: this.$route.query.c,
      assessCategoryCode: this.$route.query.ac,
    }
  },
  mounted() {
    this.navActiveInit(1, '问卷管理', '评估管理')
  },
  destroyed() {
    this.navActiveInit(2, '问卷管理', '评估管理', '/questionTemplate')
  },
  methods: {
    handleFilter() {
      this.page = 1
      this.getDataList()
    },
  },
}
</script>
